import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/feedback.css";

import {
  server_post_data,
  addFeedback,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
} from "../CommonJquery/CommonJquery.js";

export default function FeedBackModal({ showModal, handleClose }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [improvementText, setImprovementText] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false); // New state to control form visibility

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTextChange = (event) => {
    setImprovementText(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Selected Option:", selectedOption);
    console.log("Improvement Text:", improvementText);

    // Simulate form submission logic
    await handleSaveChangesdynamic("feedbackForm", addFeedback);

    // After submission, hide the form and title, and show the thank you message
    setFormSubmitted(true);
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    if (form_data) {
      let fd_from = combiled_form_data(form_data);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          if (Response.data.error) {
            // Handle error
          } else {
            // Handle success
          }
        })
        .catch((error) => {
          // Handle network error
        });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      className="FeedBackModal"
    >
      {/* Hide the header if the form is submitted */}
      {!formSubmitted && (
        <Modal.Header
          closeButton
          className="no-border"
          style={{ fontSize: "13px", padding: "2rem" }}
        >
          <Modal.Title>We Want to Hear from You</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={{ fontSize: "13px" }}>
        <div className="container">
          {!formSubmitted ? (
            <form id="feedbackForm" onSubmit={handleSubmit}>
              <label className="improvement-label">Rating</label>
              <div className="checkbox-group">
                {[...Array(5)].map((_, index) => (
                  <label key={index} className="circle-checkbox">
                    <input
                      type="checkbox"
                      id="feedbackForm"
                      name="feedback_rating"
                      value={index + 1}
                      checked={selectedOption === (index + 1).toString()}
                      onChange={handleOptionChange}
                    />
                    {index + 1}
                  </label>
                ))}
              </div>

              <label className="improvement-label">How can we improve?</label>
              <textarea
                id="feedbackForm"
                name="feedback_text"
                className="improvement-textarea"
                value={improvementText}
                onChange={handleTextChange}
              />
              <Button type="submit" className="submit-button">
                Submit
              </Button>
            </form>
          ) : (
            <div className="thank-you-message text-center">
              <h4>Thank you for your valuable feedback!</h4>
            </div>
          )}
        </div>
      </Modal.Body>

      {formSubmitted && (
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="closeBTn" variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
