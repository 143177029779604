import NavbarOption from "../assets/NavbarOption.svg";
import React, { useState } from "react";
import "./css/headerTextbar.css";
import { Link } from "react-router-dom";
import FeedBackModal from "./FeedBackModal";
const HeaderTextEditor = ({ toggleSidebar }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <FeedBackModal showModal={showModal} handleClose={handleClose} />
      <div className="TextBarHead">
        <div className="ImgLogoCont">
          <div className="imgLogo">
            <button onClick={toggleSidebar}>
              {" "}
              <img src={NavbarOption} alt="sidebar"></img>
            </button>
          </div>
          <h5>
            {" "}
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              {" "}
              Chat gpt Notepad
            </Link>
          </h5>
        </div>
        <div className="secondCont">
          <p className="nav-item">
            <button
              className="nav-link"
              style={{ fontSize: "12px" }}
              onClick={handleShow}
            >
              FEEDBACK
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderTextEditor;
