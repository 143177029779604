import { useEffect, useState } from "react";
import HeaderNew from "./HeaderNew";
// import Header4 from "./Header4";

import Footer from "./Footer";
import "./css/home.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import chatgptss from "../assets/chatgptss.png";
import findIcon from "../assets/findIcon.svg";
import printIcon from "../assets/printIcon.svg";
import wordCounter from "../assets/wordCounter.svg";
import Autosave from "../assets/Autosave.svg";
import simpleinterFace from "../assets/simpleinterFace.svg";

import spellcheck from "../assets/spellcheck.svg";
import AdComponent from "./AdComponent";

const Home = () => {
  const featuresData = [
    {
      imgSrc: simpleinterFace,
      title: "Simple Interface",
      text: "Experience a beautifully crafted minimalistic interface designed to keep you focused on your writing. Our Online Notepad offers a plain-text editor without any distractions, making it the best online notepad for seamless note-taking.",
    },
    {
      imgSrc: Autosave,
      title: "Auto Text Cleanup",
      text: "Our unique auto text cleanup feature automatically removes asterisks (*) and hashes (#) from the text copied from AI tools like ChatGPT, Gemini, and Copilot. This ensures your notes are clean and well-formatted.",
    },
    {
      imgSrc: wordCounter,
      title: "Real-Time Word Count",
      text: "Keep track of your word count effortlessly with our real-time word counter. It updates dynamically as you type, ensuring you always know your document's length.",
    },
    {
      imgSrc: findIcon,
      title: "Find & Replace",
      text: "Easily locate and replace any word or phrase within your document using the Find & Replace feature. This functionality allows you to quickly make corrections and substitutions throughout your text.",
    },
    {
      imgSrc: spellcheck,
      title: "Spellcheck",
      text: "Utilize your browser's native spell checker to instantly identify and correct spelling mistakes. Simply right-click on the underlined word and select the correct spelling.",
    },
    {
      imgSrc: printIcon,
      title: "Print and Save",
      text: "Get a hard copy of your notes or save them directly to your system as a text file. With just a click of a button, you can print your document or save it for later use on any text editor.",
    },
    // {
    //   imgSrc: spacialChacter,
    //   title: "Variety of Fonts and Special Characters",
    //   text: "Choose from a variety of fonts to customize your text. Effortlessly add emojis and special characters to enhance your notes and make them more expressive.",
    // },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const handleNavigation = (path) => {
    setTimeout(() => {
      window.location.href = path;
    }, 200);
  };

  return (
    <>
      <HeaderNew />

      {/*-----Hero Section---*/}
      <section className="heroSec">
        <div className="container">
          <div className="content">
            <h1>Free browser-based text editor</h1>
            <p>
              A simple online tool that makes taking down notes easier and more
              convenient.
            </p>
            <button
              className="startUsingbtn"
              onClick={() => handleNavigation("/TextEditorNew")}
            >
              {" "}
              Start using now
            </button>
          </div>
        </div>
        <div className="Chatgptimg">
          <img src={chatgptss} alt="chatgpt"></img>
        </div>
      </section>
      {/*-------Feture----*/}
      <section className="features">
        <div className="container">
          {isMobile ? (
            <Slider {...sliderSettings}>
              {featuresData.map((feature, index) => (
                <div key={index}>
                  <div className="card text-center">
                    <div className="card-body">
                      <img
                        className="card-title"
                        src={feature.imgSrc}
                        alt={feature.title}
                      />
                      <h5 className="card-title">{feature.title}</h5>
                      <p className="card-text">{feature.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="row">
              {featuresData.map((feature, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card text-center">
                    <div className="card-body">
                      <img
                        className="card-title"
                        src={feature.imgSrc}
                        alt={feature.title}
                      />
                      <h5 className="card-title">{feature.title}</h5>
                      <p className="card-text">{feature.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      {/*-----------overView------------*/}
      <section className="container">
        <AdComponent
          slot="4982546863"
          format="auto"
          fullWidthResponsive="true"
        />
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div className="overView">
              <h3>Overview</h3>
              <p>
                Online Notepad is a free, browser-based text editor that allows
                you to create and edit multiple plain-text files without the
                need for registration or login. It's perfect for quick notes,
                printing simple pages, and saving documents directly to your
                computer. Our web notepad includes essential features such as
                undo, redo, copy, cut, paste, find and replace, font formatting,
                date and time insertion, emoji list, special characters, spell
                checker, word counter, file saving, and printing.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*-----------How does it work?-----------*/}
      <section className="container">
        <AdComponent
          slot="6948017615"
          format="autorelaxed"
          fullWidthResponsive="false"
        />
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div className="overView">
              <h3>How It Works</h3>
              <p>
                Simply open your browser and visit chatgptnotepad.com to start
                your writing journey. There’s no need for any setup or
                installation – just begin typing directly in the editor. Our
                intuitive interface allows you to focus on your content without
                distractions. You can easily save your work, print it, or
                continue editing at any time. Whether you're jotting down quick
                notes or composing detailed documents, our Online Notepad is
                designed to provide a seamless and efficient writing experience
                from start to finish. Just write, save, and print with ease.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*-----------Browser Compatibility-----------*/}
      <section className="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div className="overView">
              <h3>Browser Compatibility</h3>
              <p>
                ChatGPT Notepad is designed to be compatible with all modern web
                browsers. Whether you prefer Mozilla Firefox, Safari, Opera,
                Microsoft Edge, Google Chrome, or even the Steam browser, our
                online notepad will function seamlessly across all these
                platforms. Enjoy the same efficient note-taking experience
                regardless of your preferred browser. Thank you for choosing
                ChatGPT Notepad, the best online tool for all your note-taking
                and text editing needs.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*----------About----------*/}
      <section className="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div className="overView">
              <h3>About</h3>
              <p>
                Welcome to ChatGPT Notepad, your ultimate online notepad
                designed for seamless and efficient note-taking. Our mission is
                to provide a simple and distraction-free platform where you can
                focus on your writing. Whether you need a reliable text editor
                for daily tasks or a robust tool for extensive note-taking,
                ChatGPT Notepad is here to help.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
